@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.changeZoomContainer {
  @include display-flex();
  @include flex-direction(column);
  @include align-items(flex-start);
  @include justify-content(flex-start);
  position: absolute;
  cursor: pointer;
  left: 585px;
  top: 127px;
  width: 20px;
  height: 40px;
  @media only screen and (max-width: $screen-sm) {
    display: none;
  }

  &__button {
    background-image: url("../../assets/zoom-buttons/zoom_plus.svg");
    background-color: transparent;
    background-size: 100% auto;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    border: none;

    &:focus {
      outline: none;
    }

    &:first-child {
      border-bottom: none;
    }

    &:hover {
      background-image: url("../../assets/zoom-buttons/zoom_plus_hover.svg");
    }
  }
}

.change-zoom__minus {
  background-position: 50% 100%;
}

.change-zoom__plus {
  background-position: 50% 0;

}
