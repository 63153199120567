@import "../../variables/variables";
@import "../../mixins/mixins";

.save-block {
  @include display-flex();
  @include flex-direction(column);
}

.save__details {
  @include font-size(16);
  line-height: 20px;
  color: #6D6E71;
  font-family: SourceSansPro-It, sans-serif;
  background-color: #ffffff;
  padding: 15px;
  @media only screen and (max-width: $screen-lg) {
    padding: 10px;
    @include font-size(14);
  }
  @media only screen and (max-width: $screen-sm) {
    padding: 15px;
    @include font-size(16);
  }

  &__customization {
    margin: 0;
    padding: 0;
  }
}

.save-block__name, .save-block__description {
  @include font-size(16);
  color: #231F20;
  font-family: SourceSansPro-It, sans-serif;
  display: block;
  background-color: #ffffff;
  margin-top: 12px;
  padding: 12.5px;
  border: 1px solid transparent;
  @media only screen and (max-width: $screen-lg) {
    @include font-size(14);
    padding: 7.5px;
  }
  @media only screen and (max-width: $screen-sm) {
    @include font-size(16);
    padding: 12.5px;
  }

  &:focus {
    outline: none;
    border: 1px solid #231F20;
  }
}

.save__description {
  @include font-size(16);
  color: #231F20;
  font-family: SourceSansPro-It, sans-serif;
  background-color: #ffffff;
  margin-top: 12px;
  padding: 15px;
  @media only screen and (max-width: $screen-lg) {
    @include font-size(14);
  }
  @media only screen and (max-width: $screen-sm) {
    @include font-size(16);
  }
}
