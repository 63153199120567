@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.logo {
  background-image: url("../../assets/icons/logo_wijck_with_slogan.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 135px;
  height: 57px;
  position: absolute;
  top: 35px;
  left: 50%;
  @include transform(translateX(-50%));
  @media only screen and (max-width: $screen-sm) {
    display: block;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin: 10px auto;
  }
}
