@import "../../mixins/mixins";
@import "../../variables/variables";

.orientation {
  @include display-flex();
}

.orientationItem {
  @include display-flex();
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  border: 1px solid transparent;
  clear: both;
  width: 73px;
  height: 73px;
  cursor: pointer;
  background-color: #ffffff;
  margin-right: 8px;

  &:focus {
    outline: none;
  }

  &:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: $screen-lg) {
    width: 55px;
    height: 55px;
  }
  @media only screen and (max-width: $screen-sm) {
    width: 73px;
    height: 73px;
  }

  &__text {
    @include user-select(none);
    @include font-size(12);
    font-family: SourceSansPro-Regular, sans-serif;
    color: #000000;
    @media only screen and (max-width: $screen-lg) {
      @include font-size(10);
    }
    @media only screen and (max-width: $screen-sm) {
      @include font-size(12);
    }
  }

  &__portrait {
    background-image: url("../../../assets/icons/portrait.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 47px;
    height: 47px;
    margin-top: -8px;
    @media only screen and (max-width: $screen-lg) {
      width: 32px;
      height: 32px;
      margin-top: -2px;
    }
    @media only screen and (max-width: $screen-sm) {
      width: 47px;
      height: 47px;
      margin-top: -8px;
    }
  }

  &__landscape {
    background-image: url("../../../assets/icons/landscape.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    margin-top: -10px;
    @media only screen and (max-width: $screen-lg) {
      width: 35px;
      height: 35px;
      margin-top: -8px;
    }
    @media only screen and (max-width: $screen-sm) {
      width: 50px;
      height: 50px;
      margin-top: -10px;
    }
  }

}

.orientationItem-active {
  border: 1px solid #231F20;
}

.chooseMapStyle-block {
  margin-top: 30px;
}

.chooseMapStyle {
  @include display-flex();
  @include flex-direction(row);
  @include flex-wrap(wrap)
}

.chooseMapStyle__item {
  @include display-flex();
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  cursor: pointer;
  border: 1px solid transparent;
  background: #ffffff;
  width: 73px;
  height: 73px;
  margin-right: 8px;
  margin-bottom: 8px;

  &:focus {
    outline: none;
  }

  &:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: $screen-lg) {
    margin-right: 5px;
    margin-bottom: 5px;
    width: 55px;
    height: 55px;
  }

  &__text {
    @include font-size(12);
    @include user-select(none);
    font-family: SourceSansPro-Regular, sans-serif;
    color: #000000;
    margin-top: 10px;
    @media only screen and (max-width: $screen-lg) {
      @include font-size(10);
      margin-top: 6px;
    }
  }

}

.wijck, .hoxton, .vogue, .kinfolk, .damsco {
  background-size: contain;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  @media only screen and (max-width: $screen-lg) {
    width: 18px;
    height: 18px;
  }
}

.wijck {
  border: 1px solid #000000;
  background-image: url("../../../assets/icons/style_damsco.svg");
}

.damsco {
  background-image: url("../../../assets/icons/style_wijck.svg");
}

.hoxton {
  background-image: url("../../../assets/icons/style_hoxton.svg");

}

.vogue {
  background-image: url("../../../assets/icons/style_vogue.svg");

}

.kinfolk {
  background-image: url("../../../assets/icons/style_kinfolk.svg");

}

.chooseMapStyle-active {
  border: 1px solid #231F20;
}

.mapStyling__text-block {
  @include display-flex();
  @include flex-direction(column);
}

.mapStyling__changeTextInput {
  @include font-size(16);
  display: block;
  padding: 12.5px;
  font-family: SourceSansPro-Regular, sans-serif;
  color: #231F20;
  background-color: #ffffff;
  margin-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid transparent;

  &:focus {
    outline: none;
    border: 1px solid #231F20;
    outline-offset: 0;
    resize: none;
    box-sizing: border-box;
  }

  @media only screen and (max-width: $screen-lg) {
    padding: 7.5px;
    @include font-size(14);
  }
  @media only screen and (max-width: $screen-sm) {
    padding: 12.5px;
    @include font-size(16);
  }
}

.mapStyling__defaultParams {
  @include font-size(12);
  font-family: SourceSansPro-It, sans-serif;
  color: #231F20;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 100%;
  &:hover {
    font-weight: 900;
  }

  &:focus {
    outline: none;
  }
}
