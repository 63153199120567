
$text-color: #231F20;
$fontSize-normal: 16px;

$screen-xxxs: 412px;
$screen-xxs: 576px;
$screen-xs: 768px;
$screen-sm: 992px;
$screen-md: 1200px;
$screen-lg: 1440px;
