@import "reset/reset.css";
@import "./variables/variables";
@import "mixins/mixins";

@include font-face('DidotLTStd-Italic', '../../assets/fonts/DidotLTStd-Italic/DidotLTStd-Italic');
//@include font-face('LeagueGothic', '../../assets/fonts/LeagueGothic/LeagueGothic');
@include font-face('LeagueGothic', '../../assets/fonts/LeagueGothicForClient/LeagueGothic');
@include font-face('SourceSansPro-Black', '../../assets/fonts/SourceSansPro-Black/SourceSansPro-Black');
@include font-face('SourceSansPro-BlackIt', '../../assets/fonts/SourceSansPro-BlackIt/SourceSansPro-BlackIt');
@include font-face('SourceSansPro-Bold', '../../assets/fonts/SourceSansPro-Bold/SourceSansPro-Bold');
@include font-face('SourceSansPro-BoldIt', '../../assets/fonts/SourceSansPro-BoldIt/SourceSansPro-BoldIt');
@include font-face('SourceSansPro-ExtraLight', '../../assets/fonts/SourceSansPro-ExtraLight/SourceSansPro-ExtraLight');
@include font-face('SourceSansPro-ExtraLightIt', '../../assets/fonts/SourceSansPro-ExtraLightIt/SourceSansPro-ExtraLightIt');
@include font-face('SourceSansPro-It', '../../assets/fonts/SourceSansPro-It/SourceSansPro-It');
@include font-face('SourceSansPro-Light', '../../assets/fonts/SourceSansPro-Light/SourceSansPro-Light');
@include font-face('SourceSansPro-LightIt', '../../assets/fonts/SourceSansPro-LightIt/SourceSansPro-LightIt');
@include font-face('SourceSansPro-Regular', '../../assets/fonts/SourceSansPro-Regular/SourceSansPro-Regular');
@include font-face('SourceSansPro-Semibold', '../../assets/fonts/SourceSansPro-Semibold/SourceSansPro-Semibold');
@include font-face('SourceSansPro-SemiboldIt', '../../assets/fonts/SourceSansPro-SemiboldIt/SourceSansPro-SemiboldIt');

div, ul, li, a {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}


body {
  overflow: hidden;
  position: fixed;
  bottom: 0;
}

.container-fluid {
  @include display-flex();
  position: relative;
  height: 100vh;
  width: 100vw;

  @media only screen and (max-width: $screen-sm) {
    @include flex-direction(column-reverse);
  }
}

.tab-name {
  @include font-size(18);
  font-family: SourceSansPro-Semibold, sans-serif;
  color: #231F20;
  margin-bottom: 10px;
  @media only screen and (max-width: $screen-lg) {
    @include font-size(14);
    margin-bottom: 8px;
  }
  @media only screen and (max-width: $screen-sm) {
    margin-bottom: 5px;
    @include font-size(18);
  }
}

@import "./pages/home/home";
@import "./layout/leftSidebar/leftSidebar";
@import "./layout/leftSidebarB2B/leftSidebarB2B";
