.left-sidebar {
  &__close {
    display: none;
    border: none;
    z-index: 15;
    @media only screen and (max-width: $screen-sm) {
      display: block;
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: #f4f4f5;
      cursor: pointer;
      right: 0;
    }

    &-icon {
      display: block;
      height: 100%;

      &:before, &:after {
        content: '';
        position: absolute;
        top: 10px;
        width: 2px;
        height: 32px;
      }

      &:before {
        left: calc(50% - 1px);
        border-left: 2px solid #000000;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:after {
        right: calc(50% - 1px);
        border-right: 2px solid #000000;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:hover {
        &:before, &:after {
          border-color: #f00;
        }
      }
    }
  }
  &__close-hidden {
    display: none;
  }
}
