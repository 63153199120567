@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.add-to-cart {
  @include display-flex();
  background-color: #ffffff;
  margin-top: 30px;
  padding: 15px;
  min-height: 165px;
  height: 165px;
  max-height: 165px;

  &__frame-block__image {
    @include align-self(center);
    background-color: #c9c8c8;
    margin-bottom: 1px;
  }

  .add-to-cart__frame-block {
    position: relative;
    @include display-flex();
    @include flex-direction(column);
    @include align-items(center);
    @include align-self(flex-start);
    margin-right: 10px;
    padding: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-portrait {
    .add-to-cart__frame-block__image {
      height: 100px;
      max-height: 100px;
      width: 80px;
    }
    .add-to-cart__frame-block__image-wijck-black,  .add-to-cart__frame-block__image-wijck1-black,
    .add-to-cart__frame-block__image-wijck2-black,  .add-to-cart__frame-block__image-wijck3-black {
      height: 120px;
      max-height: 120px;
      width: 80px;
    }
    .add-to-cart__frame-block__image-wijck-noFrame,  .add-to-cart__frame-block__image-wijck1-noFrame,
    .add-to-cart__frame-block__image-wijck2-noFrame,  .add-to-cart__frame-block__image-wijck3-noFrame{
      height: 120px;
      max-height: 120px;
      width: 80px;
    }
    .add-to-cart__frame-block__image-wijck-gold, .add-to-cart__frame-block__image-wijck1-gold,
    .add-to-cart__frame-block__image-wijck2-gold, .add-to-cart__frame-block__image-wijck3-gold{
      height: 115px;
      max-height: 115px;
      width: 80px;
    }
  }

  &-landscape {
    .add-to-cart__frame-block__image {
      height: 50px;
      max-height: 50px;
      width: 101px;
    }

    .add-to-cart__frame-block__image-wijck-black, .add-to-cart__frame-block__image-wijck1-black,
    .add-to-cart__frame-block__image-wijck2-black, .add-to-cart__frame-block__image-wijck3-black{
      height: 65px;
      width: 101px;
    }
    .add-to-cart__frame-block__image-wijck-noFrame, .add-to-cart__frame-block__image-wijck1-noFrame
    .add-to-cart__frame-block__image-wijck2-noFrame, .add-to-cart__frame-block__image-wijck3-noFrame{
      height: 69px;
      max-height: 69px;
      width: 101px;
    }
    .add-to-cart__frame-block__image-wijck-gold, .add-to-cart__frame-block__image-wijck1-gold,
    .add-to-cart__frame-block__image-wijck2-gold, .add-to-cart__frame-block__image-wijck3-gold{
      height: 67px;
      max-height: 67px;
      width: 101px;
    }

    .add-to-cart__frame-block__trademark {
      margin-top: 1px;
    }
  }
}

.add-to-cart__frame-block__black {
  border: 3px solid #0d0d0d;

  &-landscape {
    width: 117px;
    height: 80px;
  }

  &-landscape__wijck,   &-landscape__wijck1,  &-landscape__wijck2,  &-landscape__wijck3{
    width: 117px;
    height: 82px;
  }

  &-portrait {
    width: 97px;
    height: 135px;
  }

  &-portrait__wijck, &-portrait__wijck1, &-portrait__wijck2, &-portrait__wijck3 {
    width: 97px;
    height: 135px;
  }
}

.add-to-cart__frame-block__gold {
  padding: 2px !important;
  border-image: url('../../assets/frame/frame_gold.png') 93 92 87 92 stretch stretch;
  border-width: 8px;
  border-style: solid;

  &-landscape {
    width: 117px;
    height: 87px;
  }

  &-landscape__wijck, &-landscape__wijck1, &-landscape__wijck2, &-landscape__wijck3 {
    width: 117px;
    height: 87px;
  }

  &-portrait {
    width: 97px;
    height: 135px;
  }

  &-portrait__wijck {
    width: 97px;
    height: 135px;
  }
}

.add-to-cart__frame-block__noFrame {
  @include box-shadow(0 0 6px 0 rgba(138, 138, 138, 1));
  background: #fafafa;
  border: none;
  padding: 5px;

  &-landscape {
    width: 111px;
    height: 80px;
  }

  &-landscape__wijck, &-landscape__wijck1, &-landscape__wijck2, &-landscape__wijck3 {
    width: 111px;
    height: 82px;
  }

  &-portrait {
    width: 91px;
    height: 135px;
  }

  &-portrait__wijck, &-portrait__wijck1, &-portrait__wijck2, &-portrait__wijck3 {
    width: 91px;
    height: 135px;
  }
}

.add-to-cart__frame-block__coordinate {
  font-size: 1.02px;
  line-height: 0;
  margin: 0;
  padding: 0;
  font-family: SourceSansPro-Regular, sans-serif;
  color: #231F20;
  @include display-flex();
  @include user-select(none);
}

.add-to-cart__frame-block__city {
  margin-top: 1px;
  line-height: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: 6.36px;
  letter-spacing: 0.5px;
  font-family: LeagueGothic, sans-serif;
  color: #231F20;
  @include display-flex();
  @include user-select(none);
}

.add-to-cart__frame-block__country {
  font-size: 1.91px;
  margin: 0;
  padding: 0;
  line-height: 0;
  font-family: DidotLTStd-Italic, sans-serif;
  color: #231F20;
  @include display-flex();
  @include user-select(none);
}

.add-to-cart__frame-block__trademark {
  margin-top: 3px;
  margin-bottom: 0;
  padding: 0;
  line-height: 0;
  color: #231F20;
  font-size: 1px;
  @include user-select(none);
}

.frame-canvas {
  display: inline-table;
  margin-right: 8px;
}

.frame-canvas__box-shadow {
  @include box-shadow(0 0 6px 0 rgba(138, 138, 138, 1));
}

.add-to-cart__textContainer__portrait { // blur
  @include display-flex();
  @include flex-direction(column);
  @include align-items(center);
  position: absolute;
  background-image: url('../../assets/overlay/portrait/B2.png');
  background-size: 100% 100%;
  width: 80px;
  &__black {
    bottom: 5px;
    height: 19px;
    width: 80px;
  }
  &__gold {
    bottom: 3px;
    height: 17px;
    width: 80px;
  }
  &__noFrame {
    bottom: 7px;
    height: 19px;
    width: 79px;
  }
}

.add-to-cart__textContainer__landscape {
  @include display-flex();
  @include flex-direction(column);
  @include align-items(center);
  position: absolute;

  background-image: url('../../assets/backGroundGradient/portrait/B2.png');
  background-size: 100% 100%;
  &__black {
    width: 100px;
    bottom: 6px;
  }
  &__gold {
    width: 100px;
    bottom: 2px;
  }
  &__noFrame {
    width: 100px;
    bottom: 8px;
  }
}

.add-to-cart__textContainerDefault {
  @include display-flex();
  @include flex-direction(column);
  @include align-items(center);
}
