@import '../../variables/variables';
@import "../../mixins/mixins";

.container {
  background-color: #f4f4f5;
  padding: 40px 40px 0 40px;
  height: calc(100% - 100px);
  overflow-y: auto;
  @media only screen and (max-width: $screen-lg) {
    padding: 40px 30px 0 30px;
    height: calc(100% - 75px);
  }
  @media only screen and (max-width: $screen-sm) {
    @include display-flex;
    @include flex-direction(column);
    padding: 25px 45px 150px 45px;
    position: absolute;
    bottom: 100px;
    width: 100%;
    z-index: 11;
    text-align: left;
    height: calc(87% - 100px);
  }
}

.left-sidebar-height-auto {
  height: auto !important;
}

.left-sidebar {
  @include display-flex();
  @include flex-direction(column);
  width: 21%;
  max-width: 400px;
  min-width: 325px;
  height: 100%;
  @media only screen and (max-width: $screen-lg) {
    width: 300px;
    min-width: auto;
  }
  @media only screen and (max-width: $screen-sm) {
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
  }

  &__content {
    &--hide-mobile {
      @media only screen and (max-width: $screen-sm) {
        display: none;
      }
    }
  }
}

.marginTopSection {
  margin-top: 30px;
  @media only screen and (max-width: $screen-lg) {
    margin-top: 20px;
  }
  @media only screen and (max-width: $screen-sm) {
    margin-top: 30px;
  }
}

@import "sidebarTitle";
@import "location";
@import "menu";
@import "customize";
@import "sizeExtras";
@import "addToCart";
@import "closeSidebar";
