@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.mobile-overlay {
  width: calc(100% - 25.9px * 2);
  background: transparent;
  color: white;
  opacity: 1;
  position: absolute;
  z-index: 100;
  @include flex-direction(column);
  @include align-items(center);
  display: none;

  > .glass {
    width: 100%;
    height: 100%;
    background: black;
    opacity: .6;
  }

  > .info {
    position: absolute;
    color: white;
    @include display-flex();
    @include flex-direction(column);
    @include align-items(center);
    width: 100%;

    > p {
      text-align: center;
      width: 100%;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-family: 'SourceSansPro-Regular', sans-serif;
    }

    > p:first-child {
      line-height: normal;
      font-size: 2.4rem;
      margin-top: 120px;
      font-family: 'SourceSansPro-Black', sans-serif;
    }

    > .image {
      background: transparent;
      background-image: url('../../assets/icons/Zoom_pan.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 240px;
      height: 210px;
      background-size: 100% 100%;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .mobile-overlay {
    @include display-flex();
  }
}

.mobile-overlay__default-height {
  height: 559px;
}

.mobile-overlay__wijck-height {
  height: 654px;
}

