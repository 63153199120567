#body {
  #map {
    overflow: hidden;

    &:focus {
      outline: none;
    }

    .mapboxgl-ctrl-bottom-left {
      display: none;
    }

    .mapboxgl-ctrl-bottom-right {
      display: none;
    }

    canvas {
      &:focus {
        outline: none;
      }
    }
  }
}

.map-portrait-B1 {
  .mapboxgl-canvas {
    width: 442.59px !important;
    height: 555px !important;
  }
}

.map-portrait-B1-wijck, .map-portrait-B1-wijck1, .map-portrait-B1-wijck2, .map-portrait-B1-wijck3,  .map-portrait-B1-wijck4 {
  .mapboxgl-canvas {
    width: 436.935483871px !important;
    height: 645px !important;
  }
}

.map-portrait-B2 {
  .mapboxgl-canvas {
    width: 444px !important;
    height: 555px !important;
  }
}

.map-portrait-B2-wijck, .map-portrait-B2-wijck1, .map-portrait-B2-wijck2, .map-portrait-B2-wijck3, .map-portrait-B2-wijck4 {
  .mapboxgl-canvas {
    width: 443.437499999px !important;
    height: 645px !important;

  }
}

.map-portrait-X1 {
  .mapboxgl-canvas {
    width: 504px !important;
    height: 555px !important;
  }
}

.map-portrait-X1-wijck, .map-portrait-X1-wijck1, .map-portrait-X1-wijck2, .map-portrait-X1-wijck3, .map-portrait-X1-wijck4  {
  .mapboxgl-canvas {
    width: 501.666666668px !important;
    height: 645px !important;
  }
}

.map-portrait-A3 {
  .mapboxgl-canvas {
    width: 465px !important;
    height: 555px !important;
  }
}

.map-portrait-A3-wijck, .map-portrait-A3-wijck1, .map-portrait-A3-wijck2, .map-portrait-A3-wijck3, .map-portrait-A3-wijck4 {
  .mapboxgl-canvas {
    width: 465.833333332px !important;
    height: 645px !important;
  }
}

.map-landscape-B1 {
  .mapboxgl-canvas {
    width: 555px !important;
    height: 312.32px !important;
  }
}

.map-landscape-B1-wijck, .map-landscape-B1-wijck1, .map-landscape-B1-wijck2, .map-landscape-B1-wijck3,  .map-landscape-B1-wijck4 {
  .mapboxgl-canvas {
    width: 555px !important;
    height: 375.967741936px !important;
  }
}

.map-landscape-B2 {
  .mapboxgl-canvas {
    width: 555px !important;
    height: 312px !important;
  }
}

.map-landscape-B2-wijck, .map-landscape-B2-wijck1, .map-landscape-B2-wijck2, .map-landscape-B2-wijck3, .map-landscape-B2-wijck4 {
  .mapboxgl-canvas {
    width: 555px !important;
    height: 381.5624999999px !important;
  }
}

.map-landscape-X1 {
  .mapboxgl-canvas {
    width: 555px !important;
    height: 357px !important;
  }
}

.map-landscape-X1-wijck, .map-landscape-X1-wijck1, .map-landscape-X1-wijck2, .map-landscape-X1-wijck3,  .map-landscape-X1-wijck4 {
  .mapboxgl-canvas {
    width: 555px !important;
    height: 431.666666668px !important;
  }
}

.map-landscape-A3 {
  .mapboxgl-canvas {
    width: 555px !important;
    height: 331px !important;
  }
}

.map-landscape-A3-wijck, .map-landscape-A3-wijck1, .map-landscape-A3-wijck2, .map-landscape-A3-wijck3,  .map-landscape-A3-wijck4 {
  .mapboxgl-canvas {
    width: 555px !important;
    height: 400.833333332px !important;
  }
}
