@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.button-next-step {
  @include font-size(20);
  @include display-flex();
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);
  min-height: 46px;
  margin: 20px 0 20px 0;
  width: 100%;
  background-color: #508A64;
  color: #ffffff;
  font-family: SourceSansPro-Bold, sans-serif;
  padding: 10px 5px;
  border: 1px solid #508A64;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    @include transition(0.5s);
    background-color: #457355;
    border: 1px solid #457355;
  }

  @media only screen and (max-width: $screen-lg) {
    @include font-size(15);
    padding: 4.5px 5px;
    height: 40px;
    min-height: 40px;
  }
  @media only screen and (max-width: $screen-sm) {
    @include font-size(20);
    padding: 10px 5px;
    height: 46px;
    min-height: 46px;
  }
}

.getMyGeo-button, .searchButton {
  @include display-flex();
  @include justify-content(center);
  @include align-items(center);
  background-color: #231F20;
  border: none;
  margin: 0;
  padding: 0;
  color: #ffffff;
  cursor: pointer;
  width: 100%;
  height: 46px;
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: $screen-lg) {
    height: 36px;
  }
  @media only screen and (max-width: $screen-sm) {
    height: 46px;
  }
}


.buttonZoomPlus, .buttonMinusZoom {
  background-color: #ffffff;
  display: block;
  height: 16px;
  width: 16px;
  @include font-size(20);
  color: #231F20;
  border: 2px solid #6D6E70;

  &:focus {
    outline: none;
  }
}

.buttonZoomPlus {
  border-top-right-radius: 12%;
  border-top-left-radius: 12%;
  border-bottom: none;

  &::before {
    display: block;
    top: -2px;
    left: 2px;
    content: '+';
    position: absolute;
  }
}

.buttonMinusZoom {
  border-bottom-right-radius: 12%;
  border-bottom-left-radius: 12%;

  &::before {
    position: absolute;
    display: block;
    content: '';
    right: 4px;
    top: 23px;
    width: 8px;
    height: 2px;
    background: #000000;

  }
}
