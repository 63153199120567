@import "../../mixins/mixins";
@import "../../variables/variables";

.left-sidebar__title-block {
  &-title {
    @include font-size(26);
    font-family: SourceSansPro-Black, sans-serif;
    color: #231F20;
    margin-bottom: 30px;
    @media only screen and (max-width: $screen-lg) {
      @include font-size(20);
      margin-bottom: 15px;
    }
    @media only screen and (max-width: $screen-sm) {
      @include font-size(26);
      text-align: center;
      margin: 10px 0 15px 0;
    }
  }

  &-text {
    @include font-size(16);
    font-family: SourceSansPro-Regular, sans-serif;
    word-wrap: break-word;
    color: #231F20;
    margin: 0;
    line-height: 20px;
    @media only screen and (max-width: $screen-lg) {
      @include font-size(14);
    }
    @media only screen and (max-width: $screen-sm) {
      display: none;
    }
  }
}


