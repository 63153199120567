@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.priceTagButton {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 500;
  padding: 2px 5px 0 0 ;
  &:focus {
    outline: none;
  }
}

.priceTagContainer {
  @include display-flex();
  &__image {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../assets/menu/shoppingcarts_active.svg");
    width: 26px;
    height: 26px;
  }

  &__text {
    @include font-size($fontSize-normal);
    @include display-flex();
    @include align-items(center);
    text-decoration: underline;
    font-family: SourceSansPro-Regular, sans-serif;
    color: $text-color;
    @include user-select(none)
  }
}

@media only screen and (max-width: $screen-sm) {
  .priceTagButton {
    right: 0;
    top: 0;
  }
}
