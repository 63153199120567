@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.mapSignature {
  @include display-flex();
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  &__portrait {
    &__coordinate {
      @include font-size(7);
      font-family: SourceSansPro-Regular, sans-serif;
      color: #231F20;
      margin-top: 8px;
      white-space: nowrap;
      height: 8px;
    }

    &__cityName {
      @include font-size(35);
      font-family: LeagueGothic, sans-serif;
      letter-spacing: 2.625px;
      margin-top: 7px;
      color: #231F20;
      white-space: nowrap;
      height: 40px;
    }

    &__countryName {
      @include font-size(10.5);
      font-family: DidotLTStd-Italic, sans-serif;
      color: #231F20;
      white-space: nowrap;
      height: 11px;
      margin-top: -6px;
    }

    &__trademark {
      margin-top: 19px;
      background-image: url("../../assets/icons/logo_wijck.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 1px;
      width: 100%;
      height: 100%;
    }
  }

  &__landscape {
    &__coordinate {
      @include font-size(7);
      font-family: SourceSansPro-Regular, sans-serif;
      color: #231F20;
      margin-top: 3px;
      white-space: nowrap;
      height: 8px;
    }

    &__cityName {
      @include font-size(30);
      font-family: LeagueGothic, sans-serif;
      letter-spacing: 2.625px;
      margin-top: 6px;
      color: #231F20;
      white-space: nowrap;
      height: 40px;
    }

    &__countryName {
      @include font-size(9.5);
      font-family: DidotLTStd-Italic, sans-serif;
      color: #231F20;
      white-space: nowrap;
      height: 11px;
      margin-top: -14px;
    }

    &__trademark {
      margin-top: 9px;
      background-image: url("../../assets/icons/logo_wijck.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      margin-left: 2px;
    }
  }
}

.mapSignature__textContainer{
  z-index: 1;
}

.mapSignature-portrait-B1 {
  .mapSignature__portrait__coordinate {
    font-size: 8px;
    margin-top: 13px;
  }

  .mapSignature__portrait__cityName {
    font-size: 37px;
    letter-spacing: 2.9px;
    margin-top: 5px;
    margin-left: 4px;
  }

  .mapSignature__portrait__countryName {
    font-size: 10.5px;
    margin-top: -3px;
  }

  .mapSignature__portrait__trademark {
    margin-right: -2px;
    margin-top: 20px;
  }
}

.mapSignature-portrait-X1 {
  .mapSignature__portrait__coordinate {
    font-size: 8px;
    margin-top: 13px;
  }

  .mapSignature__portrait__cityName {
    font-size: 41px;
    letter-spacing: 3px;
    margin-top: 6px;
  }

  .mapSignature__portrait__countryName {
    font-size: 12px;
    margin-top: -2px;
  }

  .mapSignature__portrait__trademark {
    margin-right: -6px;
  }
}

.mapSignature-portrait-A3 {
  .mapSignature__portrait__coordinate {
    font-size: 8px;
    margin-top: 11px;
  }

  .mapSignature__portrait__cityName {
    font-size: 38px;
    letter-spacing: 3px;
    margin-top: 5px;
  }

  .mapSignature__portrait__countryName {
    font-size: 12px;
    margin-top: -2px;
  }

  .mapSignature__portrait__trademark {
    margin-right: -6px;
  }
}

.mapSignature-portrait-A4 {
  .mapSignature__portrait__coordinate {
    font-size: 8px;
    margin-top: 4px;
  }

  .mapSignature__portrait__cityName {
    font-size: 37px;
    letter-spacing: 3px;
    margin-top: 6px;
  }

  .mapSignature__portrait__countryName {
    font-size: 10px;
    margin-top: -4px;
  }

  .mapSignature__portrait__trademark {
    margin-right: -3px;
    margin-top: 15px;
  }
}

.mapSignature-landscape-B1 {
  .mapSignature__landscape__coordinate {
    font-size: 6.5px;
    margin-top: 3.2px;
  }

  .mapSignature__landscape__cityName {
    font-size: 28.8px;
    letter-spacing: 2px;
    margin-top: 6.7px;
    margin-left: 2px;
  }

  .mapSignature__landscape__countryName {
    font-size: 8.5px;
    margin-top: -14.2px;
  }

  .mapSignature__landscape__trademark {
    margin-right: -6px;
  }
}

.mapSignature-landscape-B2 { //
  .mapSignature__landscape__coordinate {
    @include font-size(7);
    margin-top: 7px;
  }

  .mapSignature__landscape__cityName {
    @include font-size(30);
    letter-spacing:2.6252px;
    margin-top: 6px;
  }

  .mapSignature__landscape__countryName {
    @include font-size(9.5);
    margin-top: -14px;
  }

  .mapSignature__landscape__trademark {
    margin-right: -6px;
  }
}

.mapSignature-landscape-X1 { //
  .mapSignature__landscape__coordinate {
    font-size: 7px;
    margin-top: 9px;
  }

  .mapSignature__landscape__cityName {
    font-size: 35px;
    letter-spacing: 2.5px;
    margin-top: 6px;
  }

  .mapSignature__landscape__countryName {
    font-size: 11px;
    margin-top: -6px;
  }

  .mapSignature__landscape__trademark {
    margin-right: -6px;
  }
}

.mapSignature-landscape-A3 {
  .mapSignature__landscape__coordinate {
    font-size: 7px;
    margin-top: 7px;
  }

  .mapSignature__landscape__cityName {
    font-size: 32px;
    letter-spacing: 3px;
    margin-top: 6px;
  }

  .mapSignature__landscape__countryName {
    font-size: 10px;
    margin-top: -9px;
  }

  .mapSignature__landscape__trademark {
    margin-right: -6px;
  }
}

.mapSignature-landscape-A4 {
  .mapSignature__landscape__coordinate {
    font-size: 8px;
    margin-top: -2px;
  }

  .mapSignature__landscape__cityName {
    font-size: 31px;
    letter-spacing: 3px;
    margin-top: 6px;
  }

  .mapSignature__landscape__countryName {
    font-size: 9.3px;
    margin-top: -11px;
  }

  .mapSignature__landscape__trademark {
    width: 15px;
    height: 6px;
    margin-right: -2px;
    margin-top: 8px;
  }
}

.mapAndText__gold {
  padding: 8.8px 10.8px;
}


.mapSignature-portrait-B1 {
  margin-bottom: 17px;
}

.mapSignature-portrait-B2 {
  margin-bottom: 15px;
}

.mapSignature-portrait-X1 {
  margin-bottom: 9px;
}

.mapSignature-portrait-A3 {
  margin-bottom: 8px;
}


.mapSignature-landscape-B1 {
  margin-bottom: 2.2px;
}

.mapSignature-landscape-B2 {
  margin-bottom: 3px;
}

.mapSignature-landscape-X1 {
  margin-bottom: -3px;
}

.mapSignature-landscape-A3 {
  margin-bottom: -4px;
}
