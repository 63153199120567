@import "../../mixins/mixins";

.add-to-cart__params-block__title {
  @include font-size(14);
  word-break: break-word;
  font-family: SourceSansPro-Bold, sans-serif;
  color: #231F20;
  margin: 3px 0 8px 0;
}

.add-to-cart__params-block__text {
  @include font-size(12);
  display: block;
  font-family: SourceSansPro-Regular, sans-serif;
  color: #231F20;
  letter-spacing: 0;
  line-height: 16px;
  @media only screen and (max-width: $screen-lg) {
    @include font-size(11);
  }

}

.add-to-cart__params-block__customize {
  @include font-size(12);
  text-decoration: underline;
  font-family: SourceSansPro-Regular, sans-serif;
  color: #231F20;
  padding: 0;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  text-align: left;

  &:focus {
    outline: none;
  }
}
