@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.logoUnderMap {
  background-image: url("../../assets/logo/LOGO_WIJCK.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 30px;
  height: 11px;
  bottom: -16px;
  right: -3px;
}

.logoUnderMap-portrait-B1 {
  width: 15px;
  height: 5px;
  bottom: -8px;
  right: 0;
}

.logoUnderMap-portrait-B2 {
  width: 20px;
  height: 5px;
  bottom: -9px;
  right: -1px;
}

.logoUnderMap-portrait-X1 {
  width: 27px;
  height: 9px;
  bottom: -14px;
  right: -3px;
}

.logoUnderMap-portrait-A3 {
  width: 31px;
  height: 11px;
  bottom: -16px;
  right: -1px;
}

.logoUnderMap-landscape-B1 {
  width: 15px;
  height: 5px;
  bottom: -8px;
  right: 0;
}

.logoUnderMap-landscape-B2 {
  width: 20px;
  height: 5px;
  bottom: -9px;
  right: -1px;
}

.logoUnderMap-landscape-X1 {
  width: 27px;
  height: 9px;
  bottom: -14px;
  right: -3px;
}

.logoUnderMap-landscape-A3 {
  width: 31px;
  height: 11px;
  bottom: -16px;
  right: -1px;
}

