@import "../../mixins/mixins";
@import "../../variables/variables";

.mainContainer {
  @include display-flex();
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);
  @include flex(1 1 auto);
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: $screen-sm) {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.mapBlock {
  @include display-flex();
  @include align-items(flex-start);
  @include justify-content(center);
  width: 100%;
  z-index: 10;
}

.mapAndText {
  @include display-flex();
  @include flex-direction(column);
  @include user-select(none);
  border-style: solid;
  &__gold {
    border-width: 20px;
    .shadowWrapper {
      &::before {
        content: "";
        width: calc(100% + 40px);
        height: calc(100% + 40px);
        position: absolute;
        top: -20px;
        left: -20px;
      }
      &_portrait::before {
        background-image: url('../../../assets/frame/frame_gold.png');
        background-size: 100% 100%;
      }
      &_landscape::before {
        background-image: url('../../../assets/frame/frame_gold_landscape.png');
        background-size: 100% 100%;
      }
    }
  }
  &__black {
    border-color: #0d0d0d;
    padding: 20.8px;
    border-width: 18px;
  }
  &__noFrame {
    border: 20px solid transparent;
    .shadowWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include box-shadow(0 0 12px 0 rgba(138, 138, 138, 1));
      z-index: 0;
    }
  }
}

.mapWrapperOverFlowHidden {
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.trademarkWrap {
  @include display-flex();
  width: 21px;
  height: 7px;
}

.outerBorder {
  padding: 2px;
  border: 3px solid black;
  position: relative;
  box-sizing: border-box;
  @media only screen and (max-width: $screen-sm) {
    padding: 3px;
  }
}

.innerBorder {
  width: 447px;
  height: 557px;
  z-index: 1;
  left: 34px;
  top: 34px;
  border: 1px solid black;
  position: absolute;
  box-sizing: border-box;
}
