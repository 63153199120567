@import "../../mixins/mixins";

.sizesExtras-block__size__ul, .sizesExtras-block__frame__ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sizesExtras-block__size__ul__li, .sizesExtras-block__frame__ul__li {
  @include font-size(16);
  @include user-select(none);
  @include display-flex();
  @include justify-content(space-between);
  border: 1px solid transparent;
  color: #231F20;
  font-family: SourceSansPro-Regular, sans-serif;
  padding: 12.5px;
  background-color: #fff;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-active {
    border: 1px solid #231F20;

    &:focus {
      outline: none;
    }
  }
  @media only screen and (max-width: $screen-lg) {
    padding: 7.5px;
    @include font-size(14);

  }
  @media only screen and (max-width: $screen-sm) {
    padding: 12.5px;
    @include font-size(16);
  }
}

.sizesExtras-block__size__ul__li__text, .sizesExtras-block__frame__ul__li__text {
  @include font-size(16);
  @include user-select(none);
  text-align: left;
  font-family: SourceSansPro-Regular, sans-serif;
  color: #231F20;
  @media only screen and (max-width: $screen-lg) {
    @include font-size(14);

  }
  @media only screen and (max-width: $screen-sm) {
    @include font-size(16);
  }
}
