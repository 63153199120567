@import "../../variables/variables";
@import "../../mixins/mixins";

.react-geocoder {
  color: red;
  width: 300px;
}

.react-geocoder-results {
  color: blue;
  height: 200px;
}

.mapboxgl-ctrl-geocoder {
  display: block;
  width: 100%;
}

body {
  .mapboxgl-ctrl-geocoder--button {
    margin-top: 5px;
    display: block !important;

    &:focus {
      outline: none;
    }
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    display: none;
  }

  .mapboxgl-ctrl-geocoder--icon {
    margin-top: 0;
    @media only screen and (max-width: $screen-lg) {
      margin-top: -3px;
    }
    @media only screen and (max-width: $screen-sm) {
      margin-top: 0;
    }
  }

  .mapboxgl-ctrl-geocoder {
    box-shadow: none;
    padding: 3px;
    width: 100%;
    min-width: auto;
    margin: 1px;
    border-radius: 0;
    @media only screen and (max-width: $screen-sm) {
      width: 100%;
      max-width: 100%;
    }
  }

  .mapboxgl-ctrl-geocoder--input {
    padding: 6px 15px;
    height: 36px;
    @include font-size(16);
    @media only screen and (max-width: $screen-lg) {
      @include font-size(13);
      height: 27px;
    }
    @media only screen and (max-width: $screen-sm) {
      @include font-size(16);
      width: 100%;
      height: 36px;

    }
  }
}

.citySearch-input {
  @include font-size(16);
  font-family: SourceSansPro-Regular, sans-serif;
  color: #231F20;
  border: 1px solid #231F20;
  width: 70%;
  background-color: #ffffff;
  height: 46px;
  @media only screen and (max-width: $screen-lg) {
    height: 36px;
  }
  @media only screen and (max-width: $screen-sm) {
    height: 46px;
  }
}

.findCity-block {
  @include display-flex();
  @include flex-direction(row);
  width: 100%;
  height: 46px;
  @media only screen and (max-width: $screen-lg) {
    height: 36px;
  }
  @media only screen and (max-width: $screen-sm) {
    height: 46px;
    padding-bottom: 45px;
  }
}

.findCity-block__buttons {
  @include display-flex();
  @include flex(0 0 30%);
  height: 46px;
  position: relative;
  @media only screen and (max-width: $screen-lg) {
    height: 36px;
  }
  @media only screen and (max-width: $screen-sm) {
    height: 46px;
  }
}


.bestsellersCity-block {
  @include display-flex();
  @include flex-direction(column);
}

.citySelectButton {
  @include user-select(none);
  @include font-size(16);
  font-family: SourceSansPro-Regular, sans-serif;
  color: #231F20;
  text-align: center;
  padding: 12.5px 0;
  background-color: #ffffff;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #231F20;
  }

  &:focus {
    outline: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: $screen-lg) {
    padding: 7.5px 0;
    @include font-size(14);
  }
  @media only screen and (max-width: $screen-sm) {
    padding: 12.5px 0;
    @include font-size(16);
  }
}

.hover {
  border: 1px solid #231F02;
}

.location-icon {
  @include transition(0.2s);
  width: 48px;
  height: 46px;
  position: absolute;
  @media only screen and (max-width: $screen-lg) {
    width: 38px;
    height: 36px;
  }
  @media only screen and (max-width: $screen-sm) {
    width: 48px;
    height: 46px;
  }
}

.searchIcon {
  background: url("../../../assets/icons/search_icon.svg");

  &:hover {
    background: url("../../../assets/icons/search_icon_bold.svg");
  }
}

.currentLocation {
  background-image: url("../../../assets/icons/locate.svg");

  &:hover {
    background-image: url("../../../assets/icons/locate_bold.svg");
  }
}

.whiteStrip {
  display: inline-block;
  @include flex(0 0 1px);
  height: 100%;
  background-color: #231F20;

  &::before {
    display: block;
    position: absolute;
    content: '';
    clear: both;
    background: #ffffff;
    top: 8px;
    width: 1px;
    height: 30px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    @media only screen and (max-width: $screen-sm) {
      height: 26px;
      top: 10px;
      width: 1px;
    }
  }
}
