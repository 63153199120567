@import "../../mixins/mixins";
@import "../../variables/variables";

.left-sidebar__menu {
  @include display-flex();
  position: fixed;
  bottom: 0;
  z-index: 11;
  width: 21%;
  max-width: 400px;
  min-width: 325px;
  height: 100px;
  @media only screen and (max-width: $screen-lg) {
    width: 300px;
    min-width: auto;
    height: 75px;
  }
  @media only screen and (max-width: $screen-sm) {
    width: 100%;
    max-width: 100%;
    min-width: auto;
    height: 100px;
  }

  &-item-b2c {
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    @include transition(0.3s);
    @include font-size(12);
    height: 100%;
    width: 25%;
    cursor: pointer;
    background-color: #231F20;
    color: #ffffff;
    font-family: SourceSansPro-Regular, sans-serif;
    text-align: center;
    @media only screen and (max-width: $screen-lg) {
      @include font-size(10);
    }
    @media only screen and (max-width: $screen-sm) {
      @include font-size(12);
    }

    &-img-b2c {
      display: block;
      margin-bottom: 10px;
      height: 30px;
      width: 30px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      @media only screen and (max-width: $screen-lg) {
        width: 24px;
        height: 24px;
      }
      @media only screen and (max-width: $screen-sm) {
        width: 30px;
        height: 30px;
      }

      &-0 {
        background-image: url("../../../assets/menu/location.svg");
      }

      &-1 {
        background-image: url("../../../assets/menu/customize.svg");
      }

      &-2 {
        background-image: url("../../../assets/menu/size-extra.svg");
      }

      &-3 {
        background-image: url("../../../assets/menu/shoppingcarts.svg");
      }
    }

    &--active, &:hover {
      color: #000000;
      background-color: #f4f4f5;

      .left-sidebar__menu-item-b2c-img-b2c {
        &-0 {
          background-image: url("../../../assets/menu/location_active.svg");
        }

        &-1 {
          background-image: url("../../../assets/menu/customize_active.svg");
        }

        &-2 {
          background-image: url("../../../assets/menu/size-extra_active.svg");
        }

        &-3 {
          background-image: url("../../../assets/menu/shoppingcarts_active.svg");
        }
      }
    }

    &--active-disable {
      color: #ffffff;
      background-color: #231F20;

      .left-sidebar__menu-item-img {
        &-0 {
          background-image: url("../../../assets/menu/location.svg");
        }

        &-1 {
          background-image: url("../../../assets/menu/customize.svg");
        }

        &-2 {
          background-image: url("../../../assets/menu/size-extra.svg");
        }

        &-3 {
          background-image: url("../../../assets/menu/shoppingcarts.svg");
        }
      }
    }
  }

  &-item-b2b {
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    @include transition(0.3s);
    @include font-size(12);
    width: 33.33%;
    height: 100%;
    cursor: pointer;
    background-color: #231F20;
    color: #ffffff;
    font-family: SourceSansPro-Regular, sans-serif;
    text-align: center;
    @media only screen and (max-width: $screen-lg) {
      @include font-size(10);
    }
    @media only screen and (max-width: $screen-sm) {
      @include font-size(12);
    }

    &-img-b2b {
      display: block;
      margin-bottom: 10px;
      height: 30px;
      width: 30px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      @media only screen and (max-width: $screen-lg) {
        width: 24px;
        height: 24px;
      }
      @media only screen and (max-width: $screen-sm) {
        width: 30px;
        height: 30px;
      }
      &-0 {
        background-image: url("../../../assets/menu/location.svg");
      }

      &-1 {
        background-image: url("../../../assets/menu/customize.svg");
      }

      &-2 {
        background-image: url("../../../assets/menu/save.svg");
      }
    }

    &--active, &:hover {
      color: #000000;
      background-color: #f4f4f5;

      .left-sidebar__menu-item-b2b-img-b2b {
        &-0 {
          background-image: url("../../../assets/menu/location_active.svg");
        }

        &-1 {
          background-image: url("../../../assets/menu/customize_active.svg");
        }

        &-2 {
          background-image: url("../../../assets/menu/save_active.svg");
        }
      }
    }
    &--active-disable {
      color: #ffffff;
      background-color: #231F20;
      .left-sidebar__menu-item-img {
        &-0 {
          background-image: url("../../../assets/menu/location.svg") ;
        }

        &-1 {
          background-image: url("../../../assets/menu/customize.svg");
        }

        &-2 {
          background-image: url("../../../assets/menu/save.svg");
        }
      }
    }

  }
}
