
.outerThumbnailBorder__black {
  border: 1px solid;
  position: absolute;
  top: 5px;
  &__landscape {
    width: 103px;
    height: 66px;
  }
  &__portrait {
    width: 82px;
    height: 120px;
  }
}

.outerThumbnailBorder__gold {
  border: 1px solid;
  position: absolute;
  top: 2px;
  &__landscape {
    width: 103px;
    height: 68px;
  }

  &__portrait {
    width: 82px;
    height: 115px;
  }
}

.outerThumbnailBorder__noFrame {
  border: 1px solid;
  position: absolute;
  top: 5px;
  &__landscape {
    width: 103px;
    height: 70px;
  }
  &__portrait {
    width: 81px;
    height: 125px;
  }
}
