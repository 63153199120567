@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.MapSignatureOnMap {
  @include display-flex();
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  background-size: 100% 100%;
  position: absolute;
  z-index: 3;
  bottom: 2px;
  left: 0;
  right: 0;
  height: 85px;

  &-portrait {
    &-B1 {
      background-image: url('../../assets/overlay/portrait/B1.png');
    }
    &-B2 {
      background-image: url('../../assets/overlay/portrait/B2.png');
    }
    &-X1 {
      background-image: url('../../assets/overlay/portrait/X1.png');
    }
    &-A3 {
      background-image: url('../../assets/overlay/portrait/A3.png');
    }
  }

  &-landscape {
    &-B1 {
      background-image: url('../../assets/overlay/landscape/B1.png');
    }
    &-B2 {
      background-image: url('../../assets/overlay/landscape/B2.png');
    }
    &-X1 {
      background-image: url('../../assets/overlay/landscape/X1.png');
    }
    &-A3 {
      background-image: url('../../assets/overlay/landscape/A3.png');
    }
  }
}

.MapSignatureOnMap__portrait {
  height: 130px;

  &__coordinate {
    @include font-size(7);
    font-family: SourceSansPro-Regular, sans-serif;
    color: #231F20;
    margin-top: 4px;
    white-space: nowrap;
    height: 8px;
  }

  &__cityName {
    @include font-size(35);
    font-family: LeagueGothic, sans-serif;
    letter-spacing: 2.625px;
    margin-top: 7px;
    color: #231F20;
    white-space: nowrap;
    height: 40px;
  }

  &__countryName {
    @include font-size(10.5);
    font-family: DidotLTStd-Italic, sans-serif;
    color: #231F20;
    white-space: nowrap;
    height: 11px;
    margin-top: -6px;
  }

  .marginBottom {
    margin-bottom: 15px;
  }
}

.MapSignatureOnMap__landscape {
  height: 140px;

  &__coordinate {
    @include font-size(7);
    font-family: SourceSansPro-Regular, sans-serif;
    color: #231F20;
    margin-top: 3px;
    white-space: nowrap;
    height: 8px;
  }

  &__cityName {
    @include font-size(30);
    font-family: LeagueGothic, sans-serif;
    letter-spacing: 2.625px;
    margin-top: 6px;
    color: #231F20;
    white-space: nowrap;
    height: 40px;
  }

  &__countryName {
    @include font-size(9.5);
    font-family: DidotLTStd-Italic, sans-serif;
    color: #231F20;
    white-space: nowrap;
    height: 11px;
    margin-top: -14px;
  }

  .marginBottom {
    margin-bottom: 5px;
  }
}

.MapSignatureOnMap__textContainer {
  z-index: 1;
}

.MapSignatureOnMap-portrait-B1 {
  .MapSignatureOnMap__portrait__coordinate {
    font-size: 6px;
    margin-top: 8px;
    height: 11px;
  }

  .MapSignatureOnMap__portrait__cityName {
    font-size: 35px;
    letter-spacing: 3.3px;
    margin-top: 6px;
    margin-left: 4px;
    height: 35px;
  }

  .MapSignatureOnMap__portrait__countryName {
    font-size: 10.5px;
    margin-top: 0;
  }
}

.MapSignatureOnMap-portrait-B2 {
  .MapSignatureOnMap__portrait__coordinate {
    font-size: 6px;
    margin-top: 16px;
    height: 11px
  }

  .MapSignatureOnMap__portrait__cityName {
    font-size: 34px;
    letter-spacing: 3px;
    margin-top: 4px;
    margin-left: 0;
    height: 34px;
  }

  .MapSignatureOnMap__portrait__countryName {
    font-size: 10.5px;
    margin-top: 0;
    letter-spacing: 0;
  }
}

.MapSignatureOnMap-portrait-X1 {
  .MapSignatureOnMap__portrait__coordinate {
    font-size: 8px;
    margin-top: 18px;
    height: 11px;
  }

  .MapSignatureOnMap__portrait__cityName {
    font-size: 39px;
    letter-spacing: 3.6px;
    margin-top: 4px;
    margin-left: 2px;
    height: 36px;
  }

  .MapSignatureOnMap__portrait__countryName {
    font-size: 12px;
    margin-top: 3px;
  }

  .MapSignatureOnMap__portrait__trademark {
    margin-right: -6px;
  }
}

.MapSignatureOnMap-portrait-A3 {
  .MapSignatureOnMap__portrait__coordinate {
    font-size: 8px;
    margin-top: 22px;
    height: 11px;
  }

  .MapSignatureOnMap__portrait__cityName {
    font-size: 38px;
    letter-spacing: 2.5px;
    margin-top: 3px;
    height: 38px;
  }

  .MapSignatureOnMap__portrait__countryName {
    font-size: 10.5px;
    margin-top: 0;
  }

  .MapSignatureOnMap__portrait__trademark {
    margin-right: -6px;
  }
}


.MapSignatureOnMap-landscape-B1 {
  .MapSignatureOnMap__landscape__coordinate {
    font-size: 7px;
    margin-top: -4px;
    height: 11px;
  }

  .MapSignatureOnMap__landscape__cityName {
    font-size: 31.8px;
    letter-spacing: 2.4px;
    margin-top: 3px;
    margin-left: -2px;
    height: auto;
  }

  .MapSignatureOnMap__landscape__countryName {
    font-size: 9px;
    margin-top: 0;
    height: 11px;
  }
}

.MapSignatureOnMap-landscape-B2 {
  .MapSignatureOnMap__landscape__coordinate {
    font-size: 7px;
    margin-top: 12px;
    height: 11px;
  }

  .MapSignatureOnMap__landscape__cityName {

    height: auto;
    margin-top: 1px;
    font-size: 32px;
    letter-spacing: 2.2px;
  }

  .MapSignatureOnMap__landscape__countryName {
    font-size: 9.5px;
    margin-top: 0;
    height: 11px;
  }
}

.MapSignatureOnMap-landscape-X1 {
  .MapSignatureOnMap__landscape__coordinate {
    font-size: 7.5px;
    margin-top: 0;
    height: 11px;
  }

  .MapSignatureOnMap__landscape__cityName {
    font-size: 35px;
    letter-spacing: 2.5px;
    margin-top: 3px;
    height: auto;
    margin-left: -4px;
  }

  .MapSignatureOnMap__landscape__countryName {
    font-size: 10.5px;
    margin-top: 0;
    margin-left: -4px;
    height: 11px;
  }
}

.MapSignatureOnMap-landscape-A3 {
  .MapSignatureOnMap__landscape__coordinate {
    font-size: 8px;
    margin-top: 18px;
    height: 11px;
  }

  .MapSignatureOnMap__landscape__cityName {
    font-size: 32px;
    letter-spacing: 2.5px;
    margin-top: 4px;
    height: auto;
    margin-left: 0;
  }

  .MapSignatureOnMap__landscape__countryName {
    font-size: 10px;
    margin-top: 0;
    height: 11px;
  }
}

.mapAndText__gold {
  padding: 8.8px 10.8px;
}


//  == portrait ==
.MapSignatureOnMap-portrait-B1 {
  height: 132px;
}

.MapSignatureOnMap-portrait-B2 {
  height: 147px;
}

.MapSignatureOnMap-portrait-X1 {
  height: 181px;
}

.MapSignatureOnMap-portrait-A3 {
  height: 174px;
}

//  == portrait ==


// == landscape ==

.MapSignatureOnMap-landscape-B1 {
  height: 119px;
}

.MapSignatureOnMap-landscape-B2 {
  height: 136px;
}

.MapSignatureOnMap-landscape-X1 {
  height: 148px;
}

.MapSignatureOnMap-landscape-A3 {
  height: 155px;
}

// == landscape ==


//.MapSignatureOnMap-landscape-B1 {
//  height: 132px;
//}
//
//.MapSignatureOnMap-landscape-B2 {
//  height: 148px;
//}
//
//.MapSignatureOnMap-landscape-X1 {
//  height: 176px;
//}
//
//.MapSignatureOnMap-landscape-A3 {
//  height: 176px;
//}

//.MapSignatureOnMap-landscape-B1 {
//  .MapSignatureOnMap__landscape__coordinate {
//    font-size: 7px;
//    margin-top: -7px;
//    height: 11px;
//  }
//
//  .MapSignatureOnMap__landscape__cityName {
//    font-size: 35.8px;
//    letter-spacing: 3px;
//    margin-top: 4px;
//    margin-left: 3px;
//    height: auto;
//  }
//
//  .MapSignatureOnMap__landscape__countryName {
//    font-size: 9.5px;
//    height: 11px;
//    margin: 0;
//  }
//}
//
//.MapSignatureOnMap-landscape-B2 {
//  .MapSignatureOnMap__landscape__coordinate {
//    font-size: 8px;
//    margin-top: 10px;
//    height: 11px;
//  }
//
//  .MapSignatureOnMap__landscape__cityName {
//    height: auto;
//    margin-top: 4px;
//    font-size: 35px;
//    letter-spacing: 2.625px;
//  }
//
//  .MapSignatureOnMap__landscape__countryName {
//    font-size: 10.5px;
//    margin-top: 0;
//    height: 11px;
//  }
//}
//
//.MapSignatureOnMap-landscape-X1 {
//  .MapSignatureOnMap__landscape__coordinate {
//    font-size: 8px;
//    margin-top: 0;
//    height: 11px;
//  }
//
//  .MapSignatureOnMap__landscape__cityName {
//    font-size: 39px;
//    letter-spacing: 3.5px;
//    margin-top: 5px;
//    height: auto;
//    margin-left: 2px;
//  }
//
//  .MapSignatureOnMap__landscape__countryName {
//    font-size: 12px;
//    margin-top: 0;
//    height: 11px;
//  }
//}
//
//.MapSignatureOnMap-landscape-A3 {
//  .MapSignatureOnMap__landscape__coordinate {
//    font-size: 8px;
//    margin-top: 18px;
//    height: 11px;
//  }
//
//  .MapSignatureOnMap__landscape__cityName {
//    font-size: 37px;
//    letter-spacing: 3px;
//    margin-top: 4px;
//    height: auto;
//    margin-left: 2px;
//  }
//
//  .MapSignatureOnMap__landscape__countryName {
//    font-size: 11px;
//    margin-top: 0;
//    height: 11px;
//  }
//}
